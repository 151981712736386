import dynamic from 'next/dynamic';
import Head from 'next/head';
import React from 'react';

import { __STAGING__, APPLICANT_SITE_URL } from '../../utils/constants';

const MessageCountHead = dynamic(
  () => import('./MessageCountHead').then((mod) => mod.MessageCountHead),
  { ssr: false },
);

const formatTitle = (title: string): string => {
  return title ? `${title} | Leland` : 'Leland';
};

const normalizeRelativeLink = (url: string) =>
  /https?:\/\//.test(url) ? url : `${APPLICANT_SITE_URL}${url}`;

export interface PageHeadProps {
  imageUrl?: string;
  canonicalLink?: string;
  noIndex?: boolean;
  prev?: string;
  next?: string;
  type?: string;
  title: string;
  description?: string;
  twitterCardType?: string;
  twitterHandle?: string;
}

const PageHead: React.FC<PageHeadProps> = ({
  title: titleProp,
  imageUrl = '/images/leland-og.png',
  canonicalLink,
  noIndex,
  prev,
  next,
  type = 'website',
  description,
  twitterCardType = 'summary_large_image',
  twitterHandle = '@joinLeland',
}) => {
  const title = formatTitle(titleProp);

  return (
    <>
      <Head>
        <meta
          key="viewport"
          name="viewport"
          content="width=device-width, initial-scale=1"
        />
        <title key="title">{title}</title>
        {canonicalLink ? (
          <link
            key="canonical-link"
            rel="canonical"
            href={normalizeRelativeLink(canonicalLink)}
          />
        ) : null}
        {noIndex || __STAGING__ ? (
          <meta name="robots" content="noindex" />
        ) : null}
        {prev ? (
          <link key="prev" rel="prev" href={normalizeRelativeLink(prev)} />
        ) : null}
        {next ? (
          <link key="next" rel="next" href={normalizeRelativeLink(next)} />
        ) : null}

        {/* OpenGraphTags */}
        <meta property="og:type" content={type} key="ogtype" />
        <meta property="og:title" content={title} key="ogtitle" />
        {description ? (
          <meta property="og:description" content={description} key="ogdesc" />
        ) : null}
        {description ? (
          <meta name="description" content={description} key="desc" />
        ) : null}
        <meta property="og:image" content={imageUrl} key="ogimage" />

        <meta key="twcard" name="twitter:card" content={twitterCardType} />
        <meta key="twhandle" name="twitter:creator" content={twitterHandle} />
        <meta key="twtitle" name="twitter:title" content={title} />
        <meta key="twdesc" name="twitter:description" content={description} />
        <meta key="twimage" name="twitter:image" content={imageUrl} />
      </Head>
      <MessageCountHead title={title} />
    </>
  );
};

export default PageHead;
