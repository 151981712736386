import { isNil, omitBy } from 'lodash-es';
import { type NextRouter } from 'next/router';
import type { UrlObject } from 'url';

import {
  type CoachSortBy,
  type GoalName,
  type IntRange,
  type Scalars,
  type SkuTier,
  type SortOrder,
  type SpecialBackground,
} from '../__generated-gql-types__/globalTypes';
import { type PostApplyApplicantCategorySelectionFragment } from '../pages/__generated-gql-types__/apply.generated';

import { categoryRedirectMapToSlug } from './category';
import { formatGoalForUrl } from './formatters';
import { getUrlObject, getUrlString } from './url';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type SearchFiltersVariables = {
  hasAdmissionCommitteeFilter?: Nullable<boolean>;
  schoolsFilter?: Nullable<Array<Scalars['SchoolUrn']['output']>>;
  companyFilter?: Nullable<Array<Scalars['CompanyUrn']['output']>>;
  coachingFocusListFilter?: Nullable<
    Array<Scalars['CoachingFocusUrn']['output']>
  >;
  hasInternationalServiceFilter?: Nullable<boolean>;
  professionalCoachFilter?: Nullable<boolean>;
  sortBy?: Nullable<CoachSortBy>;
  sortOrder?: Nullable<SortOrder>;
  hourlyRateMin?: Nullable<number>;
  hourlyRateMax?: Nullable<number>;
  goal?: string;
  category?: Nullable<string>;
  subCategory?: Nullable<string>;
  keyword?: Nullable<string>;
  specialBackgroundsFilter?: SpecialBackground[];
  skuTiers?: Nullable<SkuTier[]>;

  /* will this update require a full page reload */
  shouldDeepRoute?: Nullable<boolean>;
  /* should this change prevent scrolling to "Coaches" section */
  preventScroll?: Nullable<boolean>;
};

const maybeConvertToArray = <T extends string>(
  val: Possible<T | T[]>,
): Nullable<T[]> => {
  if (val == null) {
    return null;
  }
  if (Array.isArray(val)) {
    return val;
  }
  return val.split(',') as T[];
};

export const convertQueryVariables = (
  vars: NextRouter['query'],
): SearchFiltersVariables => {
  const {
    coachingFocusListFilter,
    hasAdmissionCommitteeFilter,
    schoolsFilter,
    companyFilter,
    hasInternationalServiceFilter,
    professionalCoachFilter,
    hourlyRateMax,
    hourlyRateMin,
    subCategoryAndPage,
    specialBackgroundsFilter,
    skuTiers,
    ...rest
  } = vars;
  return {
    ...rest,
    coachingFocusListFilter: maybeConvertToArray<
      Scalars['CoachingFocusUrn']['output']
    >(coachingFocusListFilter),
    hasAdmissionCommitteeFilter: !!hasAdmissionCommitteeFilter || null,
    schoolsFilter:
      maybeConvertToArray<Scalars['SchoolUrn']['output']>(schoolsFilter),
    companyFilter:
      maybeConvertToArray<Scalars['CompanyUrn']['output']>(companyFilter),
    hasInternationalServiceFilter: !!hasInternationalServiceFilter || null,
    professionalCoachFilter: !!professionalCoachFilter || null,
    hourlyRateMax: hourlyRateMax != null ? Number(hourlyRateMax) : null,
    hourlyRateMin: hourlyRateMin != null ? Number(hourlyRateMin) : null,
    skuTiers: maybeConvertToArray<SkuTier>(skuTiers as SkuTier | SkuTier[]),
    specialBackgroundsFilter:
      maybeConvertToArray<string>(specialBackgroundsFilter)?.map(
        (s) => s as SpecialBackground,
      ) ?? undefined,
  };
};

export const generateSearchUrlStringFromGoalCategories = (
  categorySelection?: Nullable<PostApplyApplicantCategorySelectionFragment>,
  goal?: GoalName,
  hourlyRateFilter?: Nullable<IntRange>,
): string => {
  const { pathname, query } = generateSearchFromGoalCategories(
    categorySelection,
    goal,
    hourlyRateFilter,
  );
  return getUrlString(pathname, query);
};

export const generateSearchUrlObjectFromGoalCategories = (
  categorySelection?: Nullable<PostApplyApplicantCategorySelectionFragment>,
  goal?: GoalName,
): UrlObject => {
  const { pathname, query } = generateSearchFromGoalCategories(
    categorySelection,
    goal,
  );
  return getUrlObject(pathname, query);
};

const generateSearchFromGoalCategories = (
  categorySelection?: Nullable<PostApplyApplicantCategorySelectionFragment>,
  goal?: GoalName,
  hourlyRateFilter?: Nullable<IntRange>,
): { pathname: string; query: UrlObject['query'] } => {
  const pathArgs: Pick<
    SearchFiltersVariables,
    'subCategory' | 'goal' | 'category'
  > =
    (categorySelection ?? goal)
      ? {
          subCategory: categorySelection?.subCategories
            ? categorySelection?.subCategories[0]?.slug
            : undefined,
          goal: categorySelection
            ? formatGoalForUrl(categorySelection.category.goal)
            : goal
              ? formatGoalForUrl(goal)
              : undefined,
          category: categorySelection
            ? categoryRedirectMapToSlug(
                categorySelection.category.slug,
                categorySelection.category.hasActiveCoach,
              )
            : null,
        }
      : {};
  const query: SearchFiltersVariables = hourlyRateFilter
    ? generateSearchQuery({
        hourlyRateMin: hourlyRateFilter?.start
          ? hourlyRateFilter.start / 100
          : null,
        hourlyRateMax: hourlyRateFilter?.end
          ? hourlyRateFilter.end / 100
          : null,
      })
    : {};

  return {
    pathname: generateSearchPath({
      goal: pathArgs.goal as Optional<GoalName>,
      categorySlug: pathArgs.category,
      subCategorySlug: pathArgs.subCategory,
    }),
    query: query,
  };
};

export interface GenerateSearchPathArgs {
  goal: Possible<GoalName>;
  categorySlug: Possible<string>;
  subCategorySlug: Possible<string>;
  page?: Possible<string>;
}

export const generateSearchPath = ({
  goal,
  categorySlug,
  subCategorySlug,
  page,
}: GenerateSearchPathArgs): string => {
  const urlFragments = ['search'];
  if (goal) {
    urlFragments.push(goal.toLowerCase());
    if (categorySlug) {
      urlFragments.push(categorySlug);
      if (subCategorySlug) urlFragments.push(subCategorySlug);
    }
    if (page && parseInt(page, 10) > 1) urlFragments.push(page);
  }
  return `/${urlFragments.join('/')}`;
};

export const BASE_SEARCH_PATH = generateSearchPath({
  goal: null,
  categorySlug: null,
  subCategorySlug: null,
});

export const generateSearchQuery = (
  searchVariables: SearchFiltersVariables,
): SearchFiltersVariables => omitBy(searchVariables, isNil);
