import {
  CardBrand,
  CareerGoalTarget,
  CoachSortBy,
  ExperienceType,
  GoalName,
  type IntroCallInterest,
  PreferredPronoun,
  SortOrder,
} from '../__generated-gql-types__/globalTypes';

const SORT_ORDER_LABELS: Record<SortOrder, string> = {
  [SortOrder.ASCENDING]: 'Pricing Low to High',
  [SortOrder.DESCENDING]: 'Pricing High to Low',
};
const COACH_SORT_BY_LABELS: Record<CoachSortBy, string> = {
  [CoachSortBy.HOURLY_RATE]: 'Hourly Rate',
  [CoachSortBy.LAST_MODIFIED_AT]: 'Last Modified At',
  [CoachSortBy.RELEVANCE]: 'Most Relevant',
  [CoachSortBy.REVIEW_NUMBER]: 'Most Reviews',
  [CoachSortBy.REVIEW_RATE]: 'Highest Rated',
};
const CARD_BRAND_LABELS: Record<CardBrand, string> = {
  [CardBrand.AMEX]: 'American Express Card',
  [CardBrand.DINERS]: 'Diners Club Card',
  [CardBrand.DISCOVER]: 'Discover Card',
  [CardBrand.JCB]: 'JCB Card',
  [CardBrand.MASTERCARD]: 'MasterCard',
  [CardBrand.UNION_PAY]: 'UnionPay Card',
  [CardBrand.UNKNOWN]: 'Unknown Card',
  [CardBrand.VISA]: 'VISA Card',
};
const GOAL_NAME_LABELS: Record<GoalName, string> = {
  [GoalName.CAREER]: 'Build your career',
  [GoalName.SCHOOL]: 'Get into school',
  [GoalName.TEST]: 'Take a test',
};
const GOAL_NAME_PAGE_LABELS: Record<GoalName, string> = Object.freeze({
  [GoalName.CAREER]: '',
  [GoalName.SCHOOL]: 'Admissions ',
  [GoalName.TEST]: 'Test Prep ',
});
const EXPERIENCE_TYPE_LABELS: Record<ExperienceType, string> = {
  [ExperienceType.ADMISSIONS_COMMITTEE]: 'Admissions Committee Experience',
  [ExperienceType.HIRING_MANAGER]: 'Hiring Manager',
};
const CAREER_GOAL_TARGET_LABELS: Record<CareerGoalTarget, string> =
  Object.freeze({
    [CareerGoalTarget.INTERNSHIP]: 'Get an internship',
    [CareerGoalTarget.FULL_TIME_JOB]: 'Land first full-time job',
    [CareerGoalTarget.PIVOT]: 'Pivot careers',
    [CareerGoalTarget.CHANGE_COMPANIES]: 'Change company',
    [CareerGoalTarget.LEVEL_UP]: 'Level up your career',
    [CareerGoalTarget.OTHER]: 'Other',
  });
const PREFERRED_PRONOUN_LABELS: Record<PreferredPronoun, string> =
  Object.freeze({
    [PreferredPronoun.HE_HIM]: 'He/Him',
    [PreferredPronoun.SHE_HER]: 'She/Her',
    [PreferredPronoun.THEY_THEM]: 'They/Them',
    [PreferredPronoun.DO_NOT_SPECIFY]: 'Do not specify',
  });

const CAPITALIZE_EXECEPTIONS = {
  gmat: 'GMAT',
  gre: 'GRE',
  and: '&',
} as const;

/**
 * Format string to upper case the first letter and lower case the remaining characters
 * @param {string} string
 * @returns capitalized string, like "Diamond hands"
 */
export const capitalize = (string: string): string => {
  const lowerCase = string.toLowerCase();
  if (lowerCase in CAPITALIZE_EXECEPTIONS) {
    return CAPITALIZE_EXECEPTIONS[
      lowerCase as keyof typeof CAPITALIZE_EXECEPTIONS
    ];
  }

  return string.slice(0, 1).toUpperCase() + string.slice(1).toLowerCase();
};

/**
 * Formats the interest string to split and capitalize it
 * @param interest
 * @returns formatted interest, Hourly or Package
 */
export const formatIntroCallInterest = (
  interest: IntroCallInterest,
): string => {
  return interest.split('_').map(capitalize).join(' ');
};

export const formatSortOrder = (order: SortOrder): string => {
  return SORT_ORDER_LABELS[order];
};

export const formatCoachSortBy = (sortBy: CoachSortBy): string => {
  return COACH_SORT_BY_LABELS[sortBy];
};

export const formatSortingFilterLabel = (label: string): string => {
  return `Sort by ${label.toLowerCase()}`;
};

export const formatCardBrandLabel = (cardBrand: CardBrand): string => {
  return CARD_BRAND_LABELS[cardBrand];
};

export const formatGoal = (goal: GoalName): string => {
  return GOAL_NAME_LABELS[goal];
};

export const formatGoalForUrl = (goal: GoalName): string => {
  return goal.toLowerCase();
};

export const formatGoalCoachesLabel = (goal?: GoalName): string => {
  return `${goal ? GOAL_NAME_PAGE_LABELS[goal] : ''}Coaches`;
};

export const formatGoalGroupClassesLabel = (goal?: GoalName): string => {
  return `${goal ? GOAL_NAME_PAGE_LABELS[goal] : ''}Group Classes`;
};

export const formatExperienceType = (
  experienceType: ExperienceType,
): string => {
  return EXPERIENCE_TYPE_LABELS[experienceType];
};

export const formatCareerGoalTarget = (target: CareerGoalTarget): string => {
  return CAREER_GOAL_TARGET_LABELS[target];
};

export const formatPreferredPronoun = (
  preferredPronoun: PreferredPronoun,
): string => {
  return PREFERRED_PRONOUN_LABELS[preferredPronoun];
};
