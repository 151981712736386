import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { type FC, useEffect, useMemo } from 'react';

import { AuthPageLayout } from '../components/AuthPageLayout';
import { LoginFormV2 } from '../components/forms/LoginFormV2';
import Page from '../components/page/Page';
import { useAuth } from '../context/AuthContext';
import {
  APPLICANT_SITE_URL,
  COACH_SITE_URL,
  isServer,
  SUBSCRIBER_DASHBOARD_URL,
} from '../utils/constants';
import { BASE_SEARCH_PATH } from '../utils/search';
import { LelandLocalStorage, RETURN_TO_KEY } from '../utils/storage';
import { getUrlObject } from '../utils/url';

const CANONICAL_LOGIN_LINK = `${APPLICANT_SITE_URL}/login`;

const LoginPage: FC = () => {
  const router = useRouter();
  const { currentUser, isLoadingUser, isNewSubscriptionExperience } = useAuth();

  const localReturnTo = useMemo(() => {
    const url = LelandLocalStorage.getItem<string>(RETURN_TO_KEY);
    LelandLocalStorage.removeItem(RETURN_TO_KEY);

    return url;
  }, []);

  const redirectUrl = isServer
    ? ''
    : ((router.query.redirect_url as Optional<string>) ??
      localReturnTo ??
      (isNewSubscriptionExperience
        ? SUBSCRIBER_DASHBOARD_URL
        : BASE_SEARCH_PATH));

  useEffect(() => {
    if (isLoadingUser) {
      return;
    }

    // Redirect if already logged in
    if (currentUser?.applicant != null) {
      void router.push(redirectUrl);
    } else if (currentUser?.coach != null) {
      void router.push(COACH_SITE_URL);
    }
  }, [
    currentUser?.applicant,
    currentUser?.coach,
    isLoadingUser,
    redirectUrl,
    router,
  ]);

  return (
    <Page noIndex title="Log in" canonicalLink={CANONICAL_LOGIN_LINK}>
      <div>
        <AuthPageLayout
          topRightLink={
            <div className="text-right">
              <p className="text-leland-gray-light">New to Leland?</p>
              <Link
                href={getUrlObject('/signup', router.query)}
                className="link link--primary font-medium"
              >
                Create an account
              </Link>
            </div>
          }
        >
          <LoginFormV2 className="w-full text-center" returnTo={redirectUrl} />
        </AuthPageLayout>
      </div>
    </Page>
  );
};

export default LoginPage;
