import {
  BrandLelandApp,
  BrandLelandSilhouette,
} from '@leland-dev/leland-ui-library';
import Image from 'next/image';
import Link from 'next/link';
import React, { type FC, type ReactNode } from 'react';

import AuthImage from '../assets/images/auth.png';

interface Props {
  children: ReactNode;
  topRightLink?: ReactNode;
  isTypeformView?: boolean;
  fullWidth?: boolean;
}

export const AuthPageLayout: FC<Props> = ({
  children,
  topRightLink,
  isTypeformView = false,
  fullWidth = false,
}) => {
  return (
    <div className="grid h-full grid-cols-12">
      {fullWidth ? null : (
        <div
          className={`relative col-span-4 hidden h-full min-h-screen bg-cover bg-center p-8 lg:block`}
        >
          <Image
            src={AuthImage}
            className="z-0 object-cover object-right"
            fill
            sizes="33vw"
            alt=""
          />
          <Link
            href="/"
            className="fixed left-6 top-6 z-10 block rounded-full bg-white p-2 shadow-lg"
          >
            <BrandLelandSilhouette className="size-7 text-leland-primary" />
          </Link>
        </div>
      )}
      <Link
        href="/"
        className="col-span-12 mt-20 block place-self-center rounded-full shadow-lg lg:hidden"
      >
        <BrandLelandApp className="size-11 rounded-lg" />
      </Link>
      <div
        className={`col-span-12 flex flex-col lg:justify-center ${
          isTypeformView
            ? `h-screen p-2 pb-8 ${fullWidth ? '' : 'lg:max-w-3xl'} lg:p-0`
            : 'p-8 lg:p-20'
        } ${fullWidth ? 'w-full items-center' : 'items-start lg:col-span-8'}`}
      >
        {isTypeformView ? (
          children
        ) : (
          <div className="max-w-md place-self-center lg:w-3/4">{children}</div>
        )}
        {topRightLink ? (
          <div className="right-5 top-4 mt-8 hidden lg:fixed lg:mt-0 lg:block">
            {topRightLink}
          </div>
        ) : null}
      </div>
    </div>
  );
};
