import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

import { LelandAutoNewExperience } from '../../utils/auto-new-experience';

import PageHead, { type PageHeadProps } from './PageHead';

type PageProps = React.PropsWithChildren<PageHeadProps>;

const Page: React.FC<PageProps> = ({ children, ...headProps }) => {
  const router = useRouter();
  useEffect(() => {
    if (router.query.autoNewExperience) {
      LelandAutoNewExperience.setAutoNewExperience();
    }
  }, [router.query.autoNewExperience]);
  return (
    <>
      <PageHead key={router.asPath} {...headProps} />
      {children}
    </>
  );
};

export default Page;
