import type { FetchResult } from '@apollo/client';
import { type GraphQLFormattedError } from 'graphql';

export const getFirstErrorMessage = (
  errors: FetchResult['errors'],
): string | undefined => {
  if (errors?.length) {
    return (errors[0].extensions?.message ??
      errors[0].message) as Optional<string>;
  }
};

export const formatErrors = (
  errors: readonly GraphQLFormattedError[],
): string => {
  return errors.map((error) => error.message).join(', ');
};
