import { IconInfo, Tooltip } from '@leland-dev/leland-ui-library';
import React, {
  type BaseSyntheticEvent,
  type ChangeEventHandler,
  type FC,
  type ReactElement,
  type ReactNode,
} from 'react';
import { type FieldError, type UseFormRegisterReturn } from 'react-hook-form';

// TODO: Pull to form utils
export const isBlank = (value?: string): boolean => {
  return (
    value === null ||
    value === undefined ||
    value === '' ||
    (value.trim && value.trim() === '')
  );
};

export interface GenericInputOption<T> {
  value: T;
  label: string | ReactElement;
}

export interface InputOption {
  value: string | number;
  label: string | ReactElement;
  subLabel?: string;
  disabled?: boolean;
  iconOnDisabled?: ReactNode;
  hidden?: boolean;
}

interface CheckboxProps {
  label: string;
  register: UseFormRegisterReturn;
  options: InputOption[];
  error?: FieldError;
  className?: string;
}

export const Checkbox: FC<CheckboxProps> = ({
  label,
  register,
  options,
  error,
  className = '',
}) => {
  const checkboxItems = options.map((option) => {
    return (
      <label
        className="mt-1 flex cursor-pointer items-center"
        key={`${register.name}-${option.value}`}
      >
        <input
          className={`form-checkbox mr-2 rounded ${className}`}
          type="checkbox"
          value={option.value}
          {...register}
        />
        {option.label}
      </label>
    );
  });

  return (
    <fieldset>
      <legend className="text-base font-normal text-leland-gray-dark">
        {label}
      </legend>
      {checkboxItems}
      {error?.message ? <ErrorMessage errorMessage={error.message} /> : null}
    </fieldset>
  );
};

interface RadioProps {
  label: string | ReactElement;
  register: UseFormRegisterReturn;
  options: InputOption[];
  onChange?: (event: BaseSyntheticEvent) => void;
  error?: FieldError;
  className?: string;
  radioClassName?: string;
  labelClassName?: string;
  fullWidth?: boolean;
  labelIsHidden?: boolean;
  hideRadioWhenSingleOption?: boolean;
}

export const Radio: FC<RadioProps> = ({
  label,
  register,
  options,
  onChange,
  error,
  className = '',
  radioClassName = '',
  labelClassName = '',
  fullWidth,
  labelIsHidden,
  hideRadioWhenSingleOption = false,
}) => {
  const hideRadioButton = options.length === 1 && hideRadioWhenSingleOption;
  const radioBoxItems = options.map((option) => {
    return option.hidden ? null : (
      <label
        className={`mt-1 flex cursor-pointer items-center text-base font-normal text-leland-gray-dark ${labelClassName}`}
        key={option.value}
      >
        {option.disabled && option.iconOnDisabled ? (
          option.iconOnDisabled
        ) : hideRadioButton ? null : (
          <input
            className={`form-radio mr-2 rounded ${radioClassName}`}
            type="radio"
            value={option.value}
            {...register}
            onChange={async (e) => {
              await register.onChange(e);
              if (onChange) onChange(e);
            }}
            disabled={option.disabled}
          />
        )}
        {option.label}
      </label>
    );
  });

  return (
    <fieldset className={fullWidth ? 'w-full' : ''}>
      <legend
        className={`${
          labelIsHidden ? 'hidden' : ''
        } text-base font-normal text-leland-gray-dark`}
      >
        {label}
      </legend>
      <div className={className}>{radioBoxItems}</div>
      {error?.message ? <ErrorMessage errorMessage={error.message} /> : null}
    </fieldset>
  );
};

interface TextareaProps {
  id?: string;
  label: ReactElement | string;
  flag?: 'Required' | 'Optional';
  subLabel?: string;
  labelClass?: string;
  labelIsHidden?: boolean;
  rightLabelContent?: ReactElement | string;
  className?: string;
  inputClassName?: string;
  defaultText?: string;
  register: UseFormRegisterReturn;
  rows: number;
  placeholder?: string;
  error?: FieldError;
  tooltip?: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
}

export const Textarea: FC<TextareaProps> = ({
  id,
  label,
  flag,
  subLabel,
  labelClass,
  labelIsHidden,
  rightLabelContent,
  className,
  inputClassName,
  defaultText,
  register,
  placeholder,
  rows,
  error,
  tooltip,
  onChange,
}) => {
  return (
    <div className={className}>
      <label className="space-y-3">
        {!labelIsHidden ? (
          <div className="space-y-1 text-lg leading-6">
            <div className="flex items-center justify-between">
              <h3 className={`text-leland-gray-dark ${labelClass}`}>
                {label}
                {flag ? (
                  <span className="ml-2 text-leland-gray-extra-light">
                    {flag}
                  </span>
                ) : null}
                {tooltip ? (
                  <Tooltip content={tooltip}>
                    <IconInfo className="ml-1 size-4 text-leland-gray-extra-light" />
                  </Tooltip>
                ) : null}
              </h3>
              {rightLabelContent}
            </div>
            {subLabel ? (
              <p className="text-leland-gray-light">{subLabel}</p>
            ) : null}
          </div>
        ) : null}
        <textarea
          id={id}
          className={`form-input block w-full resize-none rounded-lg border border-leland-gray-stroke px-4 py-3 placeholder:text-gray-400 ${inputClassName}`}
          placeholder={placeholder}
          defaultValue={defaultText}
          rows={rows}
          {...register}
          onChange={(e) => {
            void register?.onChange(e);
            onChange?.(e);
          }}
        />
        {error?.message ? <ErrorMessage errorMessage={error.message} /> : null}
      </label>
    </div>
  );
};

interface ErrorMessageProps {
  className?: string;
  errorMessage: string;
}

export const ErrorMessage: FC<ErrorMessageProps> = ({
  className,
  errorMessage,
}) => {
  return (
    <p className={`mt-2 text-sm text-leland-red ${className}`}>
      {errorMessage}
    </p>
  );
};
